var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-table',{ref:"formsTable",attrs:{"tbody-tr-class":_vm.tbodyTrClass,"headers":_vm.headers,"items":_vm.fetchForms,"table-top":"","paginate":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"gap":"1.2rem"}},[(item.migrated)?_c('feather-icon',{staticClass:"mr-1 text-success",attrs:{"icon":"CheckIcon","size":"24"}}):(item.rolled_back)?_c('feather-icon',{staticClass:"mr-1 text-secondary",attrs:{"icon":"XCircleIcon","size":"24"}}):_c('feather-icon',{staticClass:"mr-1 text-warning",attrs:{"icon":"AlertCircleIcon","size":"24"}}),_c('strong',[_vm._v(_vm._s(value))])],1)]}},{key:"cell(alerts)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value.length ? 'light-danger' : 'light-primary'}},[_vm._v(" "+_vm._s(value.length)+" Alerts ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View'),expression:"'View'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon cursor-pointer",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.openSubmission(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"16"}})],1),_c('table-map',{attrs:{"loc":[item.location.latitude, item.location.longitude]}}),_c('migrate-actions',{attrs:{"item":item,"type":4},on:{"done":_vm.actionDone}})],1)]}}])}),_c('b-modal',{attrs:{"centered":"","size":"lg","hide-footer":"","title":"Submission details"},model:{value:(_vm.showSubmission),callback:function ($$v) {_vm.showSubmission=$$v},expression:"showSubmission"}},[(_vm.showSubmission)?_c('Submission',{attrs:{"submission":_vm.currentSubmission,"form":_vm.currentSubmission.form,"no-back":"","show-form":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }