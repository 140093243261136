<template>
	<div>
		<base-table ref="workersTable" :tbody-tr-class="tbodyTrClass" :headers="headers" :items="fetchWorkers" table-top paginate>
			<template #cell(id)="data">
				<div class="d-flex justify-content-between" style="gap: 1.2rem">
					<feather-icon v-if="data.item.migrated" icon="CheckIcon" size="24" class="mr-1 text-success" />
					<feather-icon v-else-if="data.item.rolled_back" icon="XCircleIcon" size="24" class="mr-1 text-secondary" />
					<feather-icon v-else icon="AlertCircleIcon" size="24" class="mr-1 text-warning" />
					<strong>{{ data.value }}</strong>
				</div>
			</template>

			<template #cell(identity_card)="{ item }">
				<table-images :images="item.identity_card"></table-images>
			</template>

			<template #cell(actions)="data">
				<div class="d-flex align-items-center">
					<b-button
						v-b-tooltip.hover.top="'View'"
						variant="outline-secondary"
						class="btn-icon cursor-pointer mr-1"
						size="sm"
						@click="showDetails(data)"
					>
						<feather-icon icon="EyeIcon" size="16" />
					</b-button>
					<migrate-actions :item="data.item" :type="1" @done="actionDone" />
				</div>
			</template>
		</base-table>

		<b-modal v-model="detailsVisable" size="lg" title="Worker details" centered hide-footer>
			<worker-info :worker="currentWorker" />
		</b-modal>
	</div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue';
import TableImages from '@/components/TableImages.vue';
import BaseTable from '@/components/BaseTable.vue';
import WorkerInfo from '@/components/WorkerInfo.vue';
import store from '@/store';
import MigrateActions from '../migrate-actions.vue';

export default {
	name: 'MigrationsWorkers',

	components: { BaseTable, BButton, MigrateActions, TableImages, WorkerInfo },

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		detailsVisable: false,
		currentWorker: undefined,

		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'project_id', label: 'project', sortable: true },
			{ key: 'full_name', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'identity_type', sortable: true },
			{ key: 'identity_number', sortable: true },
			{ key: 'identity_card', sortable: true },
			{ key: 'actions' }
		]
	}),

	methods: {
		fetchWorkers(ctx, searchQuery) {
			return store.dispatch('migrations/getWorkers', { ...ctx, searchQuery });
		},

		showDetails(row) {
			this.currentWorker = row.item;
			this.detailsVisable = !this.detailsVisable;
		},

		actionDone() {
			this.$refs.workersTable.refresh();
		},

		tbodyTrClass(item) {
			// eslint-disable-next-line no-nested-ternary
			return item?.migrated ? 'migrated' : item?.rolled_back ? 'rolled-back' : 'not-migrated';
		}
	}
};
</script>

<style lang="scss" scoped></style>
