<template>
	<div>
		<base-table ref="itemsReportsTable" :tbody-tr-class="tbodyTrClass" :headers="headers" :items="fetchItemsReports" table-top paginate>
			<template #cell(id)="data">
				<div class="d-flex justify-content-between" style="gap: 1.2rem">
					<feather-icon v-if="data.item.migrated" icon="CheckIcon" size="24" class="mr-1 text-success" />
					<feather-icon v-else-if="data.item.rolled_back" icon="XCircleIcon" size="24" class="mr-1 text-secondary" />
					<feather-icon v-else icon="AlertCircleIcon" size="24" class="mr-1 text-warning" />
					<strong>{{ data.value }}</strong>
				</div>
			</template>

			<template #cell(actions)="{ item }">
				<div class="d-flex" style="gap: 1rem">
					<table-map :loc="[item.latitude, item.longitude]" />
					<migrate-actions :item="item" :type="3" @done="actionDone" />
				</div>
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import TableMap from '@/components/TableMap.vue';
import MigrateActions from '../migrate-actions.vue';

export default {
	name: 'MigrationsItemsReports',

	components: { BaseTable, MigrateActions, TableMap },

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'project_id', label: 'project', sortable: true },
			{ key: 'item', label: 'item', sortable: true },
			{ key: 'executed_quantity', label: 'Done', sortable: true },
			{ key: 'day_date', sortable: true },
			{ key: 'created_by', sortable: true },
			{ key: 'actions' }
		]
	}),

	methods: {
		fetchItemsReports(ctx, searchQuery) {
			return store.dispatch('migrations/getItemsReports', { ...ctx, searchQuery });
		},

		actionDone() {
			this.$refs.itemsReportsTable.refresh();
		},

		tbodyTrClass(item) {
			// eslint-disable-next-line no-nested-ternary
			return item?.migrated ? 'migrated' : item?.rolled_back ? 'rolled-back' : 'not-migrated';
		}
	}
};
</script>

<style lang="scss" scoped></style>
