<template>
	<div class="position-relative" @blur="show = false">
		<feather-icon class="menu-icon" icon="MoreVerticalIcon" size="18" @click="toggle"></feather-icon>
		<ul v-show="show" class="position-absolute context-menu">
			<slot />
		</ul>
	</div>
</template>

<script>
export default {
	name: 'ContextMenu',

	data: () => ({
		show: false
	}),

	mounted() {
		document.addEventListener('click', this.close);
	},
	beforeDestroy() {
		document.removeEventListener('click', this.close);
	},

	methods: {
		toggle() {
			this.show = !this.show;
		},
		close(e) {
			if (!this.$el.contains(e.target)) {
				this.show = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.menu-icon {
	cursor: pointer;
}

.context-menu {
	list-style: none;
	margin: 0;
	background: white;
	z-index: 999;
	outline: none;
	box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
	width: 180px;
	border: none;
	border-radius: 0.358rem;
	padding: 0.5rem 0;
	top: 0;
	right: 0;

	li {
		padding: 0.65rem 1.28rem;

		&:hover {
			background-color: #8bc53f17;
		}
		&:hover > * {
			color: #8bc53f;
		}
	}
}
</style>
