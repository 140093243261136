<template>
	<div class="">
		<b-list-group flush>
			<b-row>
				<b-col v-for="(key, i) in keys" :key="i" :data-key="i" cols="12" md="6">
					<b-list-group-item
						class="d-flex justify-content-between border-left-0 border-right-0"
						:class="{ 'border-bottom-0': i !== 16 || i !== 15 }"
					>
						<div class="">{{ startCase(key) }}</div>
						{{ typeof worker[key] === 'boolean' ? translateBoolean(worker[key]) : worker[key] || '---' }}
					</b-list-group-item>
				</b-col>
			</b-row>
		</b-list-group>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import startCase from 'lodash.startcase';
import { BListGroup, BListGroupItem, BRow, BCol } from 'bootstrap-vue';
import { translateBoolean } from '@/utils/helpers';

export default {
	name: 'WorkerDetails',

	components: { BListGroup, BListGroupItem, BRow, BCol },

	props: {
		worker: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		title: '',
	}),

	computed: {
		...mapGetters('workers', ['getReports', 'getBuyingReports']),
		keys() {
			const keysToHide = [
				'skill',
				'gender_id',
				'identity_card',
				'server_id',
				'mobile_id',
				'migrated',
				'rolled_back',
			];
			return Object.keys(this.worker).filter((k) => !keysToHide.includes(k));
		},
	},

	methods: {
		startCase,
		translateBoolean,

		getGender(gender) {
			return gender === 1 ? 'ذكر' : 'انثى';
		},
	},
};
</script>

<style lang="scss" scoped></style>
