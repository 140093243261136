<template>
	<div>
		<base-table ref="workersReportsTable" :tbody-tr-class="tbodyTrClass" :headers="headers" :items="fetchWorkers" table-top paginate>
			<template #cell(id)="data">
				<div class="d-flex justify-content-between">
					<feather-icon v-if="data.item.migrated" icon="CheckIcon" size="24" class="mr-1 text-success" />
					<feather-icon v-else-if="data.item.rolled_back" icon="XCircleIcon" size="24" class="mr-1 text-secondary" />
					<feather-icon v-else icon="AlertCircleIcon" size="24" class="mr-1 text-warning" />
					<strong>{{ data.value }}</strong>
				</div>
			</template>
			<template #cell(images)="row">
				<table-images :images="row.item.images"></table-images>
			</template>
			<template #cell(actions)="{ item }">
				<div class="d-flex" style="gap: 1rem">
					<table-map :loc="[item.latitude, item.longitude]" />
					<migrate-actions :item="item" :type="2" @done="actionDone" />
				</div>
			</template>
		</base-table>
	</div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import TableMap from '@/components/TableMap.vue';
import TableImages from '@/components/TableImages.vue';
import MigrateActions from '../migrate-actions.vue';

export default {
	name: 'MigrationsWorkersReports',

	components: { BaseTable, MigrateActions, TableMap, TableImages },

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'full_name', sortable: true },
			{ key: 'day_date', sortable: true },
			{ key: 'project_id', label: 'project', sortable: true },
			{ key: 'created_by', sortable: true },
			{ key: 'images', sortable: false },
			{ key: 'actions' }
		]
	}),

	methods: {
		fetchWorkers(ctx, searchQuery) {
			return store.dispatch('migrations/getWorkersReports', { ...ctx, searchQuery });
		},

		actionDone() {
			this.$refs.workersReportsTable.refresh();
		},

		tbodyTrClass(item) {
			// eslint-disable-next-line no-nested-ternary
			return item?.migrated ? 'migrated' : item?.rolled_back ? 'rolled-back' : 'not-migrated';
		}
	}
};
</script>

<style lang="scss" scoped></style>
