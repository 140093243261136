<template>
	<div>
		<b-tabs vertical pills nav-class="nav-left" nav-wrapper-class="col-md-2 col-12" content-class="col-12 col-md-10 mt-1 mt-md-0" lazy>
			<b-tab title="Workers">
				<WorkersTab />
			</b-tab>
			<b-tab title="Workers reports">
				<WorkersReportsTab />
			</b-tab>
			<b-tab title="Items reports">
				<ItemsReportsTab />
			</b-tab>
			<b-tab title="Forms">
				<FormsTab />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import FormsTab from './forms/Index.vue';
import WorkersTab from './workers/Index.vue';
import WorkersReportsTab from './workers-reports/Index.vue';
import ItemsReportsTab from './items-reports/Index.vue';

export default {
	name: 'Migration',

	components: { BTabs, BTab, FormsTab, WorkersTab, WorkersReportsTab, ItemsReportsTab }
};
</script>

<style lang="scss">
.table.b-table > tbody > tr.b-table-details > td {
	background-color: #fbfbfb;
	border-top: 1px solid #ebe9f1 !important;
}

.table.b-table > tbody > tr.b-table-details .list-group-item {
	background-color: transparent;
}

.not-migrated:not(.b-table-details) {
	border-left: 10px solid #ff9f43;
}

.migrated:not(.b-table-details) {
	border-left: 10px solid #8bc34a;
}

.rolled-back:not(.b-table-details) {
	border-left: 10px solid #82868b;
}
</style>
