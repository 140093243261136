<template>
	<div>
		<b-tabs pills lazy>
			<b-tab title="Beneficiary reports">
				<beneficiary-reports />
			</b-tab>
			<b-tab title="Paying reports">
				<paying-reports />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import PayingReports from './paying-reports.vue';
import BeneficiaryReports from './beneficiary-reports.vue';

export default {
	name: 'MigrationsWorkersReports',

	components: { BTabs, BTab, PayingReports, BeneficiaryReports }
};
</script>

<style lang="scss" scoped></style>
