<template>
	<div>
		<base-table ref="formsTable" :tbody-tr-class="tbodyTrClass" :headers="headers" :items="fetchForms" table-top paginate>
			<template #cell(id)="{ item, value }">
				<div class="d-flex justify-content-between" style="gap: 1.2rem">
					<feather-icon v-if="item.migrated" icon="CheckIcon" size="24" class="mr-1 text-success" />
					<feather-icon v-else-if="item.rolled_back" icon="XCircleIcon" size="24" class="mr-1 text-secondary" />
					<feather-icon v-else icon="AlertCircleIcon" size="24" class="mr-1 text-warning" />
					<strong>{{ value }}</strong>
				</div>
			</template>

			<template #cell(alerts)="{ value }">
				<b-badge :variant="value.length ? 'light-danger' : 'light-primary'"> {{ value.length }} Alerts </b-badge>
			</template>

			<template #cell(actions)="{ item }">
				<div class="d-flex" style="gap: 1rem">
					<b-button
						v-b-tooltip.hover.top="'View'"
						variant="outline-secondary"
						class="btn-icon cursor-pointer"
						size="sm"
						@click="openSubmission(item)"
					>
						<feather-icon icon="EyeIcon" size="16" />
					</b-button>
					<table-map :loc="[item.location.latitude, item.location.longitude]" />
					<migrate-actions :item="item" :type="4" @done="actionDone" />
				</div>
			</template>
		</base-table>
		<b-modal v-model="showSubmission" centered size="lg" hide-footer title="Submission details">
			<Submission v-if="showSubmission" :submission="currentSubmission" :form="currentSubmission.form" no-back show-form />
		</b-modal>
	</div>
</template>

<script>
import { VBTooltip, BButton, BBadge, BModal } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import TableMap from '@/components/TableMap.vue';
import MigrateActions from '../migrate-actions.vue';
import Submission from '../../projects/details/forms/form-report/submission/Index.vue';

export default {
	name: 'MigrationsForms',

	components: { BaseTable, MigrateActions, TableMap, BButton, BBadge, BModal, Submission },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		showSubmission: false,
		currentSubmission: undefined,

		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'project_id', label: 'Project', sortable: true },
			{ key: 'user.name', label: 'User', sortable: true },
			{ key: 'form.name', label: 'Form', sortable: true },
			{ key: 'alerts', label: 'Alerts', sortable: false },
			{ key: 'created_at.humans', label: 'Date', sortable: true },
			{ key: 'actions', label: 'Actions' },
		],
	}),

	methods: {
		fetchForms(ctx, searchQuery) {
			return store.dispatch('migrations/getSubmissions', { ...ctx, searchQuery });
		},

		actionDone() {
			this.$refs.formsTable.refresh();
		},

		tbodyTrClass(item) {
			// eslint-disable-next-line no-nested-ternary
			return item?.migrated ? 'migrated' : item?.rolled_back ? 'rolled-back' : 'not-migrated';
		},

		openSubmission(item) {
			this.currentSubmission = item;

			this.showSubmission = true;
		},

		goBack() {},
	},
};
</script>

<style lang="scss" scoped></style>
