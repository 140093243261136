<template>
	<div>
		<div class="">
			<context-menu v-if="!item.migrated">
				<li class="d-flex align-items-center" @click="migrate">
					<feather-icon icon="CheckIcon"></feather-icon>
					<a class="ml-75">Migrate</a>
				</li>
				<li v-if="!item.rolled_back" class="d-flex align-items-center" @click="openReasons">
					<feather-icon icon="RotateCwIcon"></feather-icon>
					<a class="ml-75">Rollback</a>
				</li>
			</context-menu>
		</div>
		<b-modal ref="rollbackModal" v-model="showRollback" centered title="Rollback reason" @ok="rollBack">
			<b-form>
				<validation-observer ref="simpleRules">
					<validation-provider #default="{ errors }" name="Reason" rules="required">
						<b-form-group label="Reason">
							<b-form-textarea
								v-model="reason"
								placeholder="Whats wrong with this data"
								rows="6"
								:state="errors.length > 0 ? false : null"
							></b-form-textarea>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>
				</validation-observer>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
import store from '@/store';
import { BModal, BForm, BFormGroup, BFormTextarea } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ContextMenu from '@/components/ContextMenu.vue';

export default {
	name: 'MigrateActions',

	components: { ValidationProvider, ValidationObserver, BModal, BForm, BFormGroup, BFormTextarea, ContextMenu },

	props: {
		item: {
			type: Object,
			required: true
		},
		type: {
			type: Number,
			required: true,
			validator: (val) => [1, 2, 3, 4, 5].includes(val)
		}
	},

	data: () => ({
		showRollback: false,
		reason: '',
		required
	}),

	computed: {
		itemType() {
			if (this.type === 1) return 'Worker';
			if (this.type === 2) return 'WorkerReport';
			if (this.type === 3) return 'ItemReport';
			if (this.type === 4) return 'Submission';
			if (this.type === 5) return 'WorkerPayingReport';
			return 'Worker';
		}
	},

	methods: {
		async migrate() {
			const { id } = this.item;
			await store.dispatch(`migrations/migrate${this.itemType}`, id);
			this.$emit('done');
		},

		async rollBack(bvModalEvt) {
			bvModalEvt.preventDefault();
			const success = await this.$refs.simpleRules.validate();
			if (!success) return;

			const { id } = this.item;
			await store.dispatch(`migrations/rollback${this.itemType}`, { id, reason: this.reason });
			this.$emit('done');

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.rollbackModal.toggle();
			});
		},

		openReasons() {
			this.showRollback = true;
		}
	}
};
</script>

<style lang="scss"></style>
